body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.result {
    margin: 0px 20px 0px 20px;
    padding: 20px;
    border-top: 2px dotted goldenrod;
}
.App input, .App button{
    margin: 10px;
    padding: 10px;
    border: 2px solid green;
    background: #ccc;
    -webkit-transition: .3s;
    transition: .3s;

}

.App button {
    cursor: pointer;
}

.App button:hover{
    background: #000;
    color: #fff;
    border: #000;
}



