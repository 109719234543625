.App input, .App button{
    margin: 10px;
    padding: 10px;
    border: 2px solid green;
    background: #ccc;
    transition: .3s;

}

.App button {
    cursor: pointer;
}

.App button:hover{
    background: #000;
    color: #fff;
    border: #000;
}


